<template>
  <main class="flex bg-primary-transparent xl:px-6 lg:px-6 md:px-8 px-3">
    <section class="w-full">
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <main>
        <h1 class="text-xl font-bold capitalize">my subscriptions</h1>
        <section class="w-full flex items-center">
          <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
            <dashboard-card
              class="bg-primary-color mr-6"
              @openModal="fundingWallet = true"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance</span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'"
            >
              <img
                src="@/assets/images/reward-pack.svg"
                alt="go to subscriptions"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
        </section>
      </main>
      <section class="my-12">
        <h1 class="text-lg font-bold">Quick actions</h1>
        <main
          class="
            mt-6
            grid
            xl:grid-cols-6
            lg:grid-cols-4
            md:grid-cols-3
            grid-cols-2
            gap-4
          "
        >
          <!-- <quick-actions-card
            :card-data="{
              background: 'rgba(253, 79, 0, 0.1)',
              src: 'box.svg',
              alt: 'Legend Packages',
              heading: 'Legend Packages',
              headingColor: '#FD4F00',
              content: 'Review all legend packages.',
            }"
          /> -->
          <quick-actions-card
            :card-data="{
              background: 'rgba(207, 148, 129, 0.15)',
              src: 'users.svg',
              alt: 'Pay for a friend',
              heading: 'Pay for a friend',
              headingColor: '#B55435',
              content: 'Never out of place to subscribe a friend.',
            }"
            @openModal="payingForFriend = true"
          />
        </main>
      </section>
      <section class="my-12">
        <h1 class="text-lg font-bold capitalize">Product Information</h1>
        <LoadingSpinner v-if="!subscription" />
        <main v-else class="mt-6 shadow-md xl:w-3/5 lg:w-4/5 md:w-full w-full">
          <div
            class="
              bg-white
              dark:bg-dark-mode-primary dark:text-border-color
              w-full
              flex
              xl:flex-row
              lg:flex-row
              md:flex-col
              flex-col
              items-center
              py-9
              xl:px-8
              lg:px-8
              md:px-5
              px-5
            "
          >
            <div
              class="
                flex
                md:justify-between
                justify-between
                xl:mb-0
                lg:mb-0
                md:mb-12
                mb-12
              "
            >
              <div class="pr-16">
                <div class="pb-10">
                  <h1 class="font-bold text-sm">Subsciption Name</h1>
                  <div class="text-legend-gray dark:text-border-color">
                    {{ subscription.bundle }}
                  </div>
                </div>
                <div>
                  <h1 class="font-bold text-sm">Subsciption Balance</h1>
                  <div class="text-legend-gray dark:text-border-color">
                    ₦ {{ subscription.product_balance | moneyFormat }}
                  </div>
                </div>
              </div>
              <div class="xl:pr-14 lg:pr-14 md:pr-0 pr-0">
                <div class="pb-10">
                  <h1 class="font-bold text-sm">Expiry Date</h1>
                  <div class="text-legend-gray dark:text-border-color">
                    {{ subscription.expiry }}
                  </div>
                </div>
                <div>
                  <h1 class="font-bold text-sm">Cost of Subscription</h1>
                  <div class="text-legend-gray dark:text-border-color">
                    ₦ {{ subscription.price | moneyFormat }}
                  </div>
                </div>
              </div>
            </div>
            <radial-progress-bar
              :diameter="151"
              :completed-steps="completedSteps"
              :total-steps="totalSteps"
              :stroke-width="14"
              :innerStrokeWidth="14"
              innerStrokeColor="#F9F4F3"
              startColor="#FD4F00"
              stopColor="#FD4F00"
            >
              <div class="text-xl font-bold">
                {{ subscription.expiry_in_days }}
              </div>
              <div class="text-sm text-legend-gray dark:text-border-color">
                days left
              </div>
            </radial-progress-bar>
          </div>
          <footer
            class="
              flex
              items-center
              justify-between
              px-8
              py-5
              bg-gray-100
              dark:bg-dark-mode-primary
              border-t
              dark:border-border-color
            "
          >
            <span
              class="
                text-primary-color
                dark:text-border-color
                font-semibold
                text-sm
                cursor-pointer
              "
              @click="switchingPlan = true"
              >{{
                user.is_fibre_customer
                  ? 'Request Change of Plan'
                  : 'Switch Plan'
              }}</span
            >
            <button
              class="
                text-sm text-white
                font-semibold
                bg-primary-color
                rounded-md
                py-2
                px-10
              "
              @click.prevent="renewSubscription = true"
            >
              Renew
            </button>
          </footer>
        </main>
      </section>
    </section>
    <custom-modal v-if="payingForFriend">
      <PayForFriend
        slot="cart"
        @closeModal="payingForFriend = false"
        @result="showNotificationToast"
      />
    </custom-modal>
    <custom-modal v-if="renewSubscription">
      <RenewSubscription slot="cart" @closeModal="renewSubscription = false" />
    </custom-modal>
    <custom-modal v-if="fundingWallet">
      <FundWallet slot="cart" @closeModal="fundingWallet = false" />
    </custom-modal>
    <custom-modal v-if="switchingPlan">
      <SwitchPlan
        slot="cart"
        :bundleList="bundleList"
        @closeModal="switchingPlan = false"
      />
    </custom-modal>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import QuickActionsCard from '@/components/QuickActionsCard.vue'
import RadialProgressBar from 'vue-radial-progress'
import PayForFriend from '@/components/PayForFriend.vue'
import RenewSubscription from '@/components/RenewSubscription.vue'
import FundWallet from '@/components/FundWallet.vue'
import SwitchPlan from '@/components/SwitchPlan.vue'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'Subscriptions',
  components: {
    DashboardCard,
    QuickActionsCard,
    RadialProgressBar,
    PayForFriend,
    RenewSubscription,
    FundWallet,
    SwitchPlan,
  },
  mounted() {
    this.fetchSubscription()
    this.fetchBundleList()
  },
  data() {
    return {
      subscription: null,
      totalSteps: 30,
      payingForFriend: false,
      renewSubscription: false,
      fundingWallet: false,
      showWalletBalance: false,
      showRewardPoints: false,
      switchingPlan: false,
      notification: null,
      bundleList: [],
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints', 'user']),
    completedSteps() {
      let timeElapsed

      if (this.subscription) {
        timeElapsed = this.totalSteps - this.subscription.expiry_in_days
      }

      return timeElapsed
    },
  },
  methods: {
    showNotificationToast(payload) {
      this.notification = {
        type: payload.type,
        message: payload.message,
      }
    },
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    async fetchSubscription() {
      try {
        const { status, data } = await this.axios.get('/account/subscription')

        if (status === 200) {
          this.subscription = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchBundleList() {
      try {
        const { status, data } = await this.axios.get(
          '/account/subscription/bundle-list'
        )

        if (status === 200) {
          this.bundleList = data.data.subscription
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
