<template>
  <main
    class="
      h-screen
      overflow-y-scroll
      overscroll-y-contain
      px-10
      pb-20
      relative
      text-legend-gray
      dark:text-border-color
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Change my plan
    </h1>
    <p class="my-3 text-sm mb-8 text-legend-gray dark:text-border-color">
      Select a product Bundle
    </p>
    <section
      v-for="bundle in bundleList"
      :key="bundle.id"
      :data-bundle="bundle.products_name"
      class="
        legend-bundles
        bg-white
        dark:bg-dark-mode-primary
        rounded-md
        p-6
        mb-3
        border
        shadow-md
        relative
      "
      @click="selectPlan(bundle)"
    >
      <h1 class="font-semibold">{{ bundle.products_name }}</h1>
      <section class="flex justify-between mt-2">
        <span>Monthly</span>
        <span class="text-primary-color font-bold"
          >₦{{ bundle.checkout_amount | moneyFormat }}</span
        >
      </section>
      <img
        src="@/assets/images/check-mark-circular.svg"
        :alt="bundle.products_name"
        class="absolute top-4 right-4 hidden"
      />
    </section>
    <!-- <h1 class="mt-10 mb-3 font-semibold">Do you want to Auto Renew</h1> -->
    <!-- <section
      class="
        w-full
        flex
        justify-between
        text-legend-gray
        dark:text-border-color
        uppercase
      "
    >
      <button
        class="
          w-1/2
          py-4
          rounded-lg
          shadow-lg
          bg-white
          dark:bg-dark-mode-primary
          flex
          items-center
          justify-center
          border
          mr-3
        "
        :class="{
          'border-primary-color': autorenew === 'NO',
        }"
        @click="autorenew = 'NO'"
      >
        <div
          v-if="autorenew === 'NO'"
          style="width: 18px; height: 18px"
          class="bg-primary-color rounded flex items-center justify-center"
        >
          <img src="@/assets/images/check-mark.svg" />
        </div>
        <img
          v-else
          src="@/assets/images/checkbox-unchecked.svg"
          alt="via pickup"
        />
        <span class="font-semibold uppercase text-xs ml-1.5">no, one time</span>
      </button>
      <button
        class="
          w-1/2
          py-3
          rounded-lg
          shadow-lg
          bg-white
          dark:bg-dark-mode-primary
          flex
          items-center
          justify-center
          border
          ml-3
        "
        :class="{ 'border-primary-color': autorenew === 'YES' }"
        @click="autorenew = 'YES'"
      >
        <div
          v-if="autorenew === 'YES'"
          style="width: 18px; height: 18px"
          class="bg-primary-color rounded flex items-center justify-center"
        >
          <img
            src="@/assets/images/check-mark.svg"
            alt="auto renew subscription"
          />
        </div>
        <img v-else src="@/assets/images/checkbox-unchecked.svg" />
        <span class="font-semibold uppercase text-xs ml-1.5"
          >yes, auto renew</span
        >
      </button>
    </section> -->
    <div class="mt-16 mb-8">
      <custom-input
        :inputData="{
          label: 'transaction pin',
          placeHolder: 'Enter your transaction pin',
          fieldName: 'pin',
        }"
        attributeType="password"
        @input-value="setValue"
      >
      </custom-input>
      <ValidationMessage v-if="loading && !$v.pin.required" class="-mt-6">
        <span slot="message">Enter your transaction pin</span>
      </ValidationMessage>
    </div>
    <div v-if="user && !user.has_pin" class="flex items-center">
      <span>Don't have transaction pin?</span>
      <router-link
        :to="{ name: 'Profile', params: { username: user.username } }"
        class="text-primary-color font-semibold cursor-pointer capitalize ml-2"
      >
        Click here
      </router-link>
    </div>
    <custom-button :loading="submitting" @sendAction="pay">
      <span slot="button-text">Change Plan</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SwitchPlan',
  props: {
    bundleList: {
      type: Array,
      default: null,
    },
  },
  components: { XIcon },
  data() {
    return {
      notification: null,
      newPlan: null,
      autorenew: 'NO',
      loading: false,
      submitting: false,
      pin: null,
    }
  },
  validations() {
    return {
      // newPlan: { required },
      pin: { required },
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    setValue(value) {
      this[value.field] = value.value
    },
    selectPlan(bundle) {
      this.newPlan = bundle

      const nodes = document.querySelectorAll('.legend-bundles')

      nodes.forEach((node) => {
        if (node.dataset.bundle === bundle.products_name) {
          node.classList.add('border-primary-color')
          node.children[2].classList.remove('hidden')
        } else {
          node.classList.remove('border-primary-color')
          node.children[2].classList.add('hidden')
        }
      })
    },
    async pay() {
      try {
        if (!this.newPlan) {
          this.notification = {
            type: 'error',
            message: 'You must choose a plan',
          }

          return
        }

        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status } = await this.axios.post(
          `/account/subscription/bundle-list/${this.newPlan.products_id}/subscribe`,
          {
            renew: this.autorenew,
            pin: this.pin,
          }
        )

        if (status === 200) {
          this.submitting = false
          this.loading = false
          this.$emit('closeModal')
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.submitting = false
        this.loading = false
      }
    },
  },
}
</script>

<style></style>
