<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <div
      class="
        w-80
        bg-primary-color
        text-white
        flex
        items-end
        justify-between
        px-5
        py-6
        mt-5
        mb-10
      "
    >
      <div class="flex items-center">
        <img
          src="@/assets/images/subscription-icon-light.svg"
          alt="go to subscriptions"
          class="w-8"
        />
        <div class="ml-5">
          <div>Wallet Balance</div>
          <div class="font-bold mt-2">₦{{ walletBalance | moneyFormat }}</div>
        </div>
      </div>
    </div>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Pay for a friend
    </h1>
    <div class="my-3 text-sm mb-8">You can easily top up a friend plan.</div>
    <section class="relative">
      <div class="mt-6 relative">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
            capitalize
          "
          >username</label
        ><input
          v-debounce:1000="checkUsername"
          v-model="username"
          type="text"
          placeholder="Recipient’s username"
          class="
            text-seconadry-border
            border border-border-color
            rounded-lg
            w-full
            p-3
            focus:outline-none
            bg-transparent
          "
        />
      </div>
      <div v-if="checkingUsername" class="text-sm text-secondary-green py-2">
        Checking username...
      </div>
      <ValidationMessage
        v-if="loading && !$v.username.required"
        class="absolute top-full"
      >
        <span slot="message">Provide username of beneficiary.</span>
      </ValidationMessage>
    </section>
    <section v-if="subscriptionDetail">
      <div class="text-sm text-legend-gray dark:text-border-color my-6">
        <span class="capitalize mr-1 font-semibold">{{
          subscriptionDetail.friend_username
        }}</span>
        has an already existing plan, continue to renew the plan.
      </div>
      <h1
        class="font-bold text-lg text-legend-black dark:text-border-color mb-4"
      >
        {{ subscriptionDetail.friend_username }}'s plan.
      </h1>
      <div class="mt-6 relative">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
            capitalize
          "
          >plan</label
        ><input
          v-model="currentPlan"
          type="text"
          placeholder="Legend Triple PlayX"
          readonly
          class="
            text-seconadry-border
            border border-border-color
            rounded-lg
            w-full
            p-3
            focus:outline-none
            bg-transparent
          "
        />
      </div>
      <div class="mt-6 relative">
        <custom-input
          :inputData="{
            label: 'Duration of plan in months',
            fieldName: 'duration',
            placeHolder: 'Number of months',
          }"
          attributeType="number"
          @input-value="setValue"
        >
        </custom-input>
        <ValidationMessage
          v-if="loading && !$v.duration.required"
          class="absolute top-full"
        >
          <span slot="message">Enter duration</span>
        </ValidationMessage>
      </div>

      <div class="mt-6 relative">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
            capitalize
          "
          >transaction pin</label
        ><input
          v-model="pin"
          type="password"
          placeholder="****"
          class="
            text-seconadry-border
            border border-border-color
            rounded-lg
            w-full
            p-3
            focus:outline-none
            bg-transparent
          "
        />
        <ValidationMessage
          v-if="loading && !$v.pin.required"
          class="absolute top-full"
        >
          <span slot="message">Enter transaction pin</span>
        </ValidationMessage>
      </div>
      <div v-if="user && !user.has_pin" class="flex items-center mt-3">
        <span>Don't have transaction pin?</span>
        <router-link
          :to="{ name: 'Profile', params: { username: user.username } }"
          class="
            text-primary-color
            font-semibold
            cursor-pointer
            capitalize
            ml-2
          "
        >
          Click here
        </router-link>
      </div>
      <custom-button :loading="submitting" @sendAction="pay">
        <span slot="button-text">Pay</span>
      </custom-button>
    </section>
  </main>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import errorModule from '@/util/error.handle'

export default {
  name: 'PayForFriend',
  components: {
    XIcon,
  },
  data() {
    return {
      username: '',
      pin: null,
      subscriptionDetail: null,
      currentPlan: null,
      loading: false,
      submitting: false,
      checkingUsername: false,
      notification: false,
      duration: 1,
    }
  },
  validations() {
    return {
      username: { required },
      pin: { required },
      duration: { required },
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'user']),
  },
  methods: {
    async checkUsername() {
      try {
        if (!this.username.length) {
          return
        }

        this.checkingUsername = true
        const { status, data } = await this.axios.post(
          '/account/wallet/pay-for-friend',
          {
            username: this.username,
          }
        )

        if (status === 200) {
          this.subscriptionDetail = data.data
          this.currentPlan = data.data.subscription[0].products_name
          this.checkingUsername = false
        }
      } catch (error) {
        let errorMessage

        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )

        if (error.response.status === 422) {
          errorMessage = error.response.data.errors.username[0]
        }

        this.$emit('onError', errorMessage)
        this.checkingUsername = false
      }
    },
    setValue(value) {
      this[value.field] = value.value
    },
    async pay() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status } = await this.axios.post(
          `/account/wallet/pay-for-friend/${this.subscriptionDetail.subscription[0].products_id}`,
          {
            username: this.username,
            pin: this.pin,
            type: this.subscriptionDetail.type,
            duration: this.duration,
          }
        )

        if (status === 200) {
          this.$emit('result', {
            type: 'success',
            message: 'Payment successful.',
          })
          this.submitting = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.checkingUsername = false
        this.submitting = false
        this.loading = false

        // this.$emit('closeModal')
      }
    },
  },
}
</script>
